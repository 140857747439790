.quickLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  grid-column: 4 / span 9;
  max-width: 100vw;
  overflow-x: auto;
  margin-bottom: 1.6rem;
}

.quickLinks > .quickLink {
  white-space: nowrap;
  padding: 0.8rem 3.2rem;

  &:disabled:not([data-is-loading]) {
    opacity: 1;
    cursor: default;

    &:hover {
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }
  
  &:hover:enabled {
    transition: "background-color 0.18s ease-in-out, box-shadow 0.18s, border-color 0.18s ease-in-out, color 0.18s ease-in-out, opacity 0.18s ease-in-out";
    background-color: var(--primary400);
    border-color: var(--primary600);
  }
}

@media (max-width: 768px) {
  .quickLinks {
    grid-column: 1 / span 12;
    gap: 1.2rem;
    flex-wrap: nowrap; width: fit-content;
    margin: 0.8rem -1.2rem 0;
    padding: 0 1.2rem 1rem;
  }
}

@media (max-width: 480px) {
  .quickLink {
    gap: 0.4rem;
  }
}