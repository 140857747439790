.section {
  max-width: 144rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 13rem;
  padding-right: 13rem;
}

@media screen and (max-width: 1280px) {
  .section {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
