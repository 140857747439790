.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4;
  border-radius: 9999px;
  border-width: 0.2rem;
  border-style: solid;
  padding: 0.8rem 1.6rem;
  position: relative;
  transition: background-color 0.18s ease-in-out, box-shadow 0.18s,
    border-color 0.18s ease-in-out, color 0.18s ease-in-out,
    opacity 0.18s ease-in-out;

  &:disabled:not([data-is-loading]) {
    opacity: 0.25;
    cursor: "not-allowed";
  }
}

.button.primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--primary600);
    border-color: var(--primary600);
  }
}

.button.secondary {
  background-color: var(--secondary200);
  border-color: var(--secondary200);
  color: var(--grey200);

  &:focus,
  &:active {
    background-color: var(--primary400);
    border-color: var(--primary400);
  }
}

.button.inverted {
  background-color: var(--grey100);
  border-color: var(--grey100);
  color: var(--grey900);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--grey300);
    border-color: var(--grey300);
  }
}

.button.outline {
  border-color: var(--grey900);
  color: var(--grey900);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--grey300);
  }
}

.button.invertedOutline {
  color: var(--grey100);

  &:hover,
  &:focus,
  &:active {
    background-color: "rgba(251, 251, 251, 0.175)";
  }
}

.button.secondaryOutline {
  color: var(--secondary800);
  border-color: var(--secondary800);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--grey300);
  }
}

.button.halfOutline {
  color: var(--grey700);
  border-color: var(--grey700);
  padding: 0.6rem 0.8rem;
}

.button.link {
  font-size: 1.75px;
  display: "inline-flex";
  color: var(--grey900);
  border-color: var(--grey700);
  letter-spacing: "0.05em";
}

.button.unstyled {
}

@media screen and (min-width: 768px) {
  .button {
    font-size: 1.4rem;
    padding: 1.6rem 3.2rem;
  }
  .button.halfOutline {
    padding: 2.4rem 2.8rem;
  }
}
