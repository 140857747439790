.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  max-width: 57rem;
  margin: 4.8rem auto 6.4rem;
}

.searchInput {
  width: 100%;
}

.searchButton {
  margin-left: 1.6rem;
  font-size: 1.6rem;
  line-height: normal;
  color: var(--grey900);
}