.grid {
  row-gap: 1.6rem;
  margin: 8rem auto;
  max-width: 1920px;
}

.imageGridImage {
  position: relative;
  background: var(--grey300);
}

.oneImage {
  .imageGridImage {
    grid-column: 2 / -2;
    aspect-ratio: 980/504;
  }
}

.oneImageWide {
  width: 100%;

  .imageGridImage {
    grid-column: 1 / -1;
  }
}

.twoImages {
  .imageGridImage:first-child {
    grid-column: 2 / 6;
    aspect-ratio: 1/1;
  }

  .imageGridImage:nth-child(2) {
    margin-top: 6rem;
    grid-column: 6 / 12;
    aspect-ratio: 58 / 38;
  }
}

.leftVariant {
  .imageGridImage:first-child {
    grid-column: 1 / 5;
    grid-row: 1 / 3;
    aspect-ratio: 380 / 586;
    margin-top: 6.4rem;
  }

  .imageGridImage:nth-child(2) {
    grid-column: 5 / 9;
    aspect-ratio: 1 / 1;
  }

  .imageGridImage:last-child {
    grid-column: 5 / 13;
    grid-row: 2;
    aspect-ratio: 78 / 38;
  }

  @media screen and (max-width: 768px) {
    .imageGridImage:first-child {
      margin-top: 4.8rem;
    }
  }

  @media screen and (max-width: 480px) {
    .imageGridImage:first-child {
      margin-top: 3.2rem;
    }
  }
}

.rightVariant {
  .imageGridImage:first-child {
    grid-column: 1 / 9;
    grid-row: 1;
    aspect-ratio: 78 / 38;
  }

  .imageGridImage:nth-child(2) {
    grid-column: 5 / 9;
    grid-row: 2;
    aspect-ratio: 1 / 1;
  }

  .imageGridImage:last-child {
    grid-column: 9 / 13;
    grid-row: 1 / 3;
    aspect-ratio: 380 / 586;
    margin-top: 6.4rem;
  }

  @media screen and (max-width: 768px) {
    .imageGridImage:last-child {
      margin-top: 4.8rem;
    }
  }

  @media screen and (max-width: 480px) {
    .imageGridImage:last-child {
      margin-top: 3.2rem;
    }
  }
}

.centerVariant {
  .imageGridImage:first-child {
    grid-column: 1 / 5;
    margin-top: 6.4rem;
    aspect-ratio: 38 / 40;
  }

  .imageGridImage:nth-child(2) {
    grid-column: 5 / 9;
    aspect-ratio: 380 / 586;
  }

  .imageGridImage:last-child {
    grid-column: 9 / 13;
    margin-bottom: 6.4rem;
    margin-top: auto;
    aspect-ratio: 38 / 40;
  }

  @media screen and (max-width: 768px) {
    .imageGridImage:first-child {
      margin-top: 3.6rem;
    }
    
    .imageGridImage:last-child {
      margin-top: auto;
      margin-bottom: 3.6rem;
    }
  }

  @media screen and (max-width: 480px) {
    .imageGridImage:first-child {
      margin-top: 0.8rem;
    }

    .imageGridImage:last-child {
      margin-bottom: 0.8rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .grid {
    row-gap: 1.2rem;
    margin: 16rem auto;
  }
}

@media screen and (max-width: 768px) {
  .grid {
    row-gap: 0.8rem;
    margin: 12rem auto;
  }
}

@media screen and (max-width: 480px) {
  .grid {
    row-gap: 0.8rem;
    margin: 8rem auto;
  }
}