.container.secondary {
  background-color: var(--secondary200);
}

.grid {
  margin: 40px 0;
}

.textWrapper {
  grid-column: 1  / 7;
  grid-row: 1;

  & > .title {
    font-size: 3.6rem;
    font-weight: 350;
    line-height: 1.15;
    color: var(--grey900);
    margin-bottom: 16px;
  }

  & > .text > :global(.paragraph) {
    font-size: 1.6rem;
    line-height: 1.6;
    color: var(--grey900)
  }

  &::before {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    background-color: var(--grey500);
    margin: 48px 0 32px;
  }
}

.image {
  grid-row: 1;
  grid-column: 8 / -1;
  position: relative;
  margin-right: -130px;
  align-self: center;
  aspect-ratio: 61 / 70;

  &.forceAspectRatio {
    aspect-ratio: unset;
    height: 100%;
  }
}

.reverse {
  & .image {
    grid-column: 1 / 6;
    margin-left: -130px;
    margin-right: 0;
    grid-row: 1;
  }

  & .textWrapper {
    grid-column: 7 / -1;
  }
}

.wideImage {
  & .image {
    grid-column: 5 / -1;
    aspect-ratio: 904 / 640;
  }

  &.reverse .image {
    grid-column: 1 / 8;    
  }

  & .textWrapper {
    z-index: 1;
    background-color: var(--grey100);
    height: fit-content;
    padding: 0 40px 40px 40px;
    align-self: center;
  }
}

.button {
  width: fit-content;
  margin-top: 32px;

  & > * {
    gap: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .image {
    grid-column: 7 / -1;
  }

  .textWrapper {
    grid-column: 1 / 6;
  }

  .reverse {
    & .image {
      grid-column: 1 / 7;
    }

    & .textWrapper {
      grid-column: 8 / -1;
    }
  }
}

@media screen and (max-width: 1280px) {
  .image {
    margin-right: -80px;
  }
  .reverse .image {
    margin-left: -80px;
  }
}

@media screen and (max-width: 1024px) {
  .image {
    margin-right: -40px;
  }

  .reverse .image {
    margin-left: -40px;
  }
}

@media screen and (max-width: 768px) {
  .grid > .image, .grid.reverse > .image {
    margin-right: 0;
    margin-left: 0;
    grid-column: span 12;

    &.forceAspectRatio {
      aspect-ratio: 16/9;
    }
  }

  .grid > .textWrapper, .grid.reverse > .textWrapper {
    grid-column: span 12;
    grid-row: 2;
    padding: 0;
  
    & > .text > :global(.paragraph) {
      font-size: 1.4rem;
    }
  }
}