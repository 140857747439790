@use "../../atoms/Grid/Grid.module.scss";

.moreCollections {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.title {
  font-size: 3.6rem;
  font-weight: 350;
  line-height: 1.15;
  color: var(--grey900);
  margin-bottom: 1.6rem;
  grid-column: span 12;
}

.slider {
  grid-column: span 12;
  margin: 4rem 0;
  overflow-x: hidden;

  & :global(.swiper-slide) {
    width: Grid.gridColsToWidth(4);
    margin-right: var(--column-gap);

    &:last-child {
      margin-right: 0;
    }
  }
}

.button {
  grid-column: span 12;
  width: fit-content;
  margin: 0 auto;
}