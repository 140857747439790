.intro {
  margin: 7rem 0 12.4rem 0;
  row-gap: 4.8rem;

  @media (max-width: 768px) {
    row-gap: 1.6rem;
    margin: 4.5rem 0 7.2rem 0;
  }
}

.title {
  grid-column: span 6;
  font-size: 6.0rem;
  line-height: 1.2;
  font-weight: 400;
  color: var(--grey900);

  @media (max-width: 1024px) {
    font-size: 4.8rem;
  }

  @media (max-width: 768px) {
    grid-column: span 12;
    font-size: 3.6rem;
  }

  @media (max-width: 480px) {
    font-size: 2.4rem;
  }
}


.textWrapper {
  grid-column: span 6;

  @media (max-width: 768px) {
    grid-column: span 10;
  }

  @media (max-width: 480px) {
    grid-column: span 12;
  }
}

.description {
  font-size: 2.4rem;
  line-height: 1.6;
  color: var(--grey900);
  margin-bottom: 0.8rem;

  @media (max-width: 1024px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
}

.button {
  margin-top: 4.8rem;
  width: fit-content;

  @media (max-width: 768px) {
    margin-top: 1.2rem;
  }
}

.leftImage {
  grid-column: 3 / span 4;
  grid-row: 2;
  height: fit-content;
  margin-top: 4.8rem;
  aspect-ratio: 1/1;
  position: relative;

  @media (max-width: 1024px) {
    grid-column: 2 / span 5;
  }

  @media (max-width: 768px) {
    grid-column: span 8;
    grid-row: auto;
    margin-top: 0.8rem;
  }
}

.rightImage {
  grid-column: span 6;
  grid-row: 2;
  height: fit-content;
  aspect-ratio: 580/380;
  position: relative;

  @media (max-width: 768px) {
    grid-column: 2 / span 11;
    grid-row: auto;
    position: relative;
    top: -7.6rem;
  }

  @media (max-width: 480px) {
    top: -4.8rem;
  }
}