:root {
  --white: #ffffff;
  --grey100: #fbfbfb;
  --grey200: #f5f6f6;
  --grey300: #e5e6e6;
  --grey500: #bdbfbf;
  --grey700: #6c6f6f;
  --grey800: #323434;
  --grey900: #090a0a;
  --warning50: #fefce8;
  --warning100: #fef08a;
  --warning300: #facc15;
  --warning600: #ca8a04;
  --warning800: #713f12;
  --success50: #f0fdf4;
  --success100: #bbf7d0;
  --success300: #6ad975;
  --success600: #16a34a;
  --success800: #14532d;
  --error50: #fef2f2;
  --error100: #fee2e2;
  --error300: #e82525;
  --error600: #dc2626;
  --error800: #7f1d1d;
  --transparent: rgba(255, 255, 255, 0);

  --primary200: #ccd7d3;
  --primary: #729186;
  --primary400: #aabdb6;
  --primary600: #556e65;

  --secondary: #a36931;
  --secondary100: #f6f0ea;
  --secondary200: #e5dbd9;
  --secondary600: #d89966;
  --secondary800: #7c4c43;

  --redLight: #a06571;
  --redDark: #782434;
}
