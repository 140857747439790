.specialEditions {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;

  @media (max-width: 768px) {
    margin-bottom: 3.2rem;
  }
}

.column {
  grid-column: span 6;
  position: relative;

  @media (max-width: 768px) {
    grid-column: span 12;
    padding-bottom: 0.8rem;

    &:last-child {
      padding-bottom: 0;
    }
  }

  & > h2 {
    font-size: 4rem;
    font-weight: 400;
    line-height: 0.9;
    color: var(--grey900);
    margin-bottom: 3.2rem;

    @media (max-width: 1024px) {
      font-size: 3.2rem;
    }

    @media (max-width: 768px) {
      font-size: 2.4rem;
      margin-bottom: 0.8rem;
    }
  }
}

.wrapper {
  display: block;
  aspect-ratio: 580 / 458;
  width: 100%;
  background-color: var(--grey300);
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    aspect-ratio: 352 / 200;
  }
}

.cta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  padding: 4.2rem 12.8rem 4.2rem 3.2rem;
  background: linear-gradient(180deg, rgba(9, 10, 10, 0) 0%, #090a0a 100%);

  @media (max-width: 1440px) {
    padding-right: 6.4rem;
  }

  @media (max-width: 1024px) {
    padding-right: 4.8rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;
    padding-right: 4.8rem;
  }

  & > h3 {
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1;
    color: var(--white);

    @media (max-width: 1440px) {
      font-size: 3.2rem;
    }

    @media (max-width: 1024px) {
      font-size: 2.4rem;
    }

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }

  & > a {
    margin-top: 2.4rem;
    width: fit-content;

    @media (max-width: 768px) {
      margin-top: 1.6rem;
    }
  }
}