.ourRooms {
  margin-top: 6rem;
  margin-bottom: 7rem;

  @media (max-width: 1280px) {
    margin-bottom: 2.4rem;
  }
  @media (max-width: 768px) {
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
  }
}

.grid {
  row-gap: 4.8rem;

  @media (max-width: 1280px) {
    row-gap: 3.2rem;
  }

  @media (max-width: 1024px) {
    row-gap: 2.4rem;
  }

  @media (max-width: 768px) {
    row-gap: 0.8rem;
  }
}

.title {
  grid-column: span 12;

  font-size: 6rem;
  line-height: 1.2;
  font-weight: 400;
  width: 50%;

  color: var(--grey900);

  @media (max-width: 1280px) {
    font-size: 4.8rem;
  }

  @media (max-width: 1024px) {
    font-size: 3.6rem;
  }

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
}

.buttonWrapper {
  grid-column: 1 / 5;
  grid-row: 2 / 4;

  width: fit-content;
  height: fit-content;

  @media (max-width: 1024px) {
    grid-column: span 12;
    grid-row: auto;
    margin-top: 1.6rem;
  }
}

.cardWrapper {
  grid-column: span 4;
  padding: 0;

  @media (max-width: 1024px) {
    grid-column: span 6;
  }
}