.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 10px;
}

.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--white);
  width: 64px;
  height: 32px;
  border: 1px solid var(--grey500);
  padding: 0 12px;
}

.input {
  border: none;
  width: 100%;
}

.label {
}

.slider-root {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 90%;
  height: 20px;
  margin: 0 5%;
}

.slider-track {
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
  background-color: var(--grey500);
}

.slider-range {
  position: absolute;
  background-color: var(--grey900);
  border-radius: 9999px;
  height: 100%;
}

.slider-thumb {
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--grey900);
  border-radius: 10px;
}
.slider-thumb:hover {
  background-color: var(--grey900);
}
.slider-thumb:focus {
  outline: none;
}
