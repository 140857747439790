.card {
  display: block;
  aspect-ratio: 380 / 272;
  height: 100%;
  background-color: var(--white);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.content-wrapper {
  position: absolute;
  height: 50%;
  width: 100%;
  padding: 2.4rem;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(9, 10, 10, 0) 0%, var(--grey900) 100%);
}

.title {
  height: fit-content;
  color: var(--white);
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .content-wrapper {
    height: 75%;
  }
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    height: 90%;
  }

  .content-wrapper {
    padding: 2rem;
  }
}

@media  screen and (max-width: 480px) {
  .content-wrapper {
    padding: 1rem;
  }
}