.imageBannerWrapper {
  position: relative;
  width: 100%;
  height: 20rem;
  overflow: hidden;

  .image {
    width: 100%;
    object-fit: cover;
    z-index: 2;
  }

  .overlay {
    z-index: 3;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 100%;
    width: 100%;
  }

  .title {
    z-index: 4;
    top: 40%;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 6rem;
    position: absolute;
    grid-column: 2 / span 10;
  }
}

@media only screen and (min-width: 640px) {
  .imageBannerWrapper {
    height: 35rem;
  }
}

@media only screen and (min-width: 1024px) {
  .imageBannerWrapper {
    height: 50rem;
  }
}
