.header {
  background-color: var(--secondary200);
  padding-top: 4rem;
  margin-bottom: 12rem
}

.grid {
  &::before {
    content: '';
    display: block;
    clear: both;
    background-color: var(--secondary200);
    grid-row: 1;
    grid-column: 1 / 13;
  }
}

.titleColumn {
  grid-column: span 12;
}

.line {
  width: 4rem;
  border: none;
  border-top: 0.4rem solid var(--secondary800);
  margin: 4rem 0;
}

.title {
  color: var(--secondary800);
  font-size: 2.4rem;
  font-weight: 350;
  line-height: 1.2;
  margin: 4rem 0;
}

.imageWrapper {
  width: 100%;
  position: relative;
  aspect-ratio: 335/385;
}

.textColumn {
  grid-column: span 11;
}

.subtitle {
  color: var(--secondary800);
  font-size: 2rem;
  font-weight: 350;
  line-height: 1.5;
  margin: 4rem 0 1.6rem;
}

.description {
  color: var(--grey900);
  font-size: 1.8rem;
  font-weight: 350;
  line-height: 1.4;
  margin: 1.6rem 0 4rem;
}

@media screen and (min-width: 480px) {
  .titleColumn {
    grid-column: span 8;

    & .title {
      font-size: 3.2rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .header {
    background: none;
    padding-top: 7rem;
  }

  .breadcrumb {
    margin-bottom: 4rem;
  }

  .titleColumn {
    grid-column: 1 / 7;
    grid-row: 1 / 3;
    margin-left: 4rem;
    
    & .title {
      font-size: 4rem;
    }
  }

  .textColumn {
    grid-column: 8 / 12;
    grid-row: 1;
  }
}

@media screen and (min-width: 1024px) {
  .titleColumn {
    grid-column: 1 / 6;
  }

  .textColumn {
    grid-column: 7 / 12;
  }
}