.cardLink {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}

.imageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 380/265;
  flex-shrink: 0;
}

.detailWrapper {
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--white);

  & > h3 {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: var(--grey900);
  }

  @media (max-width: 768px) {
    padding: 1.6rem;

    & > h3 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}

.textInfo {  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  width: 100%;
  margin-top: 2.2rem;

  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--grey700);
  text-transform: uppercase;

  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
  }

  @media (max-width: 1280px) {
    font-size: 1.2rem;
    line-height: 1.2rem;

    & > p {
      gap: 0.6rem;
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    & > p {
      gap: 0.4rem;
    }
  }
}