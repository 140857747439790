.grid {
  margin: 40px 0 80px;

  &::before {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    background-color: var(--grey500);
    margin-bottom: 40px;
    grid-column: 1;
  }

  &.horizontal {
    &::before {
      grid-column: 3;
    }

    & > * {
      grid-column: 3/-3;
    }
  }
}

.title {
  grid-column: 1/7;
  font-size: 3.6rem;
  color: var(--grey900);
  margin-bottom: 24px;
}

.title.large {
  font-size: 6rem;
}

.paragraph {
  grid-column: 7/13;
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--grey900);
}

@media screen and (max-width: 1440px) {
  .grid.horizontal {
    &::before {
      grid-column: 2;
    }

    & > * {
      grid-column: 2/-2;
    }
  }
}

@media screen and (max-width: 1024px) {
  .grid {
    &::before {
      grid-column: 2;
    }
  }

  .title, .paragraph {
    grid-column: 2 / -2;
  }

  .title, .title.large {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 480px) {
  .grid, .grid.horizontal {
    &::before {
      grid-column: 1;
    }

    & > .title, & > .paragraph {
      grid-column: 1/-1;
    }
  }
}