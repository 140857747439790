.wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.imageCollectionImage {
  position: relative;
  margin-bottom: 2.5rem;
}

.leftColumn {
  grid-column: 1 / 5;
}

.centerColumn {
  grid-column: 5 / 9;
  margin-top: 7.2rem;
}

.rightColumn {
  grid-column: 9 / 13;
  margin-top: 14.4rem;
}
