.container {
  margin-top: 32px;
  margin-bottom: 64px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-top: 12px;
    margin-bottom: 40px;
  }
}

.informationWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  margin-bottom: 1.6rem;
  grid-column: span 12;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.informationButtonWrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.usp {
  display: flex;
  gap: 1.6rem;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3.2rem;
  font-size: 1.6rem;

  & > .paragraph {
    padding-bottom: 0;
    color: var(--primary600);
  }
}

.desktopButton {
  @media (max-width: 1024px) {
    display: none;
  }
}

.informationTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.title {
  font-size: 4rem;
  font-weight: 400;
  color: var(--grey900);

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
}

.description {
  font-size: 2.4rem;
  font-weight: 300;
  color: var(--grey900);

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
}

.productWrapper {
  width: 100%;
  grid-column: span 12;
}

.mobileButton {
  display: none;
  margin-top: 2.4rem;
  width: fit-content;

  @media (max-width: 1024px) {
    display: flex;
  }
}