.headerFullscreen {
  position: relative;
  height: calc(80vh - 8rem);
  margin: 0.5rem 0px 3.6rem 0px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    min-height: 0;
    max-width: 100%;
    height: calc(80vh - 5.6rem);
  }

  &.first {
    margin-top: 0;
  }
}

.visualWrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  min-width: 100%;
  z-index: -1;
  aspect-ratio: 16/9;

  display: flex;
  justify-content: center;
  align-items: center;

  &.isVideo {
    min-width: auto;
  }
}

.reactPlayerWrapper {
  min-height: 56.25vw;
  min-width: 100vw;
}

.cta {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #00000055 0%, #00000000 100%);
  top: 0;

  @media screen and (max-width: 768px) {
    background: #00000055;
  }
}

.title {
  grid-column: 1 / span 4;
  color: var(--white);
  font-size: 3.9rem;
  font-weight: 300;
  line-height: 1.1;

  @media screen and (max-width: 1280px) {
    grid-column: 1 / span 5;
  }

  @media screen and (max-width: 1024px) {
    font-size: 3.2rem;
    grid-column: 1 / span 7;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
    grid-column: 1 / span 10;
  }

  @media screen and (max-width: 480px) {
    grid-column: span 12;
  }
}

.subtitle {
  grid-column: 1 / span 4;
  color: var(--white);
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.1;

  @media screen and (max-width: 1280px) {
    grid-column: 1 / span 5;
  }

  @media screen and (max-width: 1024px) {
    font-size: 3.2rem;
    grid-column: 1 / span 7;
  }

  @media screen and (max-width: 768px) {
    grid-column: 1 / span 10;
    font-size: 2.4rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
}

.paragraph {
  grid-column: 1 / span 4;
  color: var(--white);
  margin-top: 3.2rem;
  font-weight: 300;
  font-size: 2.4rem;

  @media screen and (max-width: 1280px) {
    grid-column: 1 / span 5;
  }

  @media screen and (max-width: 1024px) {
    grid-column: 1 / span 7;
    margin-top: 1.6rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
    grid-column: 1 / span 10;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.6rem;
  }
}

.button {
  margin-top: 3.2rem;
  grid-column: span 12;
  width: fit-content;

  @media screen and (max-width: 1024px) {
    margin-top: 1.6rem;
  }
  
  & svg {
    margin-left: 0.8rem;
    height: 2.4rem;
    width: 2.4rem;
  }
}
