.wrapper {
  top: 106px;
  overflow: scroll;
  flex-grow: 1;
  color: var(--grey900);
  display: flex;
  flex-direction: column;
  max-width: 25.4rem;
  overflow-y: auto;
}

.title {
  font-size: 3.7rem;
  font-weight: bold;
}

.head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  font-size: 1.4rem;
  font-weight: bold;
  column-gap: 3rem;
  row-gap: 1rem;
}

.resetButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  border-radius: 2.5rem;
  background: var(--primary);
  color: var(--grey100);
}

.filters {
  position: sticky;
  bottom: 0;
  height: 100%;
  overflow: auto;
}

@media (max-width: 768px) {
  .wrapper {
    max-width: 100%;
  }
}