.featuredCollection {
  position: relative;
  margin: 6.4rem auto;

  @media (max-width: 768px) {
    margin-top: 4rem;
  }
}

.imageWrapper {
  aspect-ratio: 1180/670;
  object-fit: cover;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    aspect-ratio: 375/237;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  margin: 0px 0px 13.4rem 8rem;

  @media (max-width: 1280px) {
    margin: 0px 0px 8.4rem 6rem;
  }

  @media (max-width: 768px) {
    margin: 0px 0px 2.5rem 3rem;
  }
}

.title {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--white);
}

.subtitle {
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 1;
  color: var(--white);
  margin-bottom: 2.8rem;

  @media (max-width: 768px) {
    font-size: 3.2rem;
    margin-bottom: 1.6rem;
  }
}

.action_text {
  width: "fit-content";
}
