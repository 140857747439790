.flex {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.2rem 5rem 5rem;
  box-sizing: border-box;
  display: flex;
}

.box {
  box-sizing: border-box;
  width: 100%;
  max-width: 360px;
}

.text {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.4rem;
}

@media screen and (min-width: 480px) {
  .text {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .flex {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .text {
    margin-bottom: 32px;
  }
}
