body {
  font-family: 'Neue Frutiger World, Arial, Helvetica, sans-serif';
  background-color: var(--grey100);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    font-family: 'Neue Frutiger World, Helvetica, sans-serif';
  }
}

html {
  font-size: 62.5%;
  font-weight: normal;
  color: var(--grey700);
  letter-spacing: -0.01em;

  /* outline only when the user is using tab */
  &:not(.user-is-tabbing) {
    a[href],
    area[href],
    input:not([disabled]),
    select:not([disabled]),
    textarea:not([disabled]),
    button:not([disabled]),
    iframe,
    [tabindex],
    [contentEditable="true"] {
      outline: none;
    }
  }
}

html[data-ios] {
  -webkit-overflow-scrolling: touch;
}

.with-c2a {
  footer {
    padding-bottom: 128px;
  }
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  width: calc(100% - 16px);
  padding: 0;
  margin-left: 8px;
  margin-right: 8px;

  @media only screen and (min-width: 600px) {
    width: 400px;
    margin-left: 0;
    margin-right: 0;
  }
}

/** Classes for the displayed toast **/
.Toastify__toast {
  background-color: transparent;
  padding: 0;
  width: 100%;
}

.Toastify__toast-body {
  background-color: transparent;
  padding: 0;
  width: 100%;
}

#nprogress {
  z-index: 9999999;

  .bar {
    background: var(--primary600);
  }

  .peg {
    box-shadow: 0 0 10px var(--primary600),
      0 0 5px var(--primary600);
  }

  .spinner {
    display: none;
  }
}

.tether-element {
  z-index: 9999;
}

[data-reach-menu] {
  z-index: 9999;
  transform: translateX(calc(-100% + 67px));
}

[data-reach-menu-button] ~ [data-reach-menu-popover] {
  position: static;
  width: 100%;
  display: flex;
}

@media (min-width: 1024px) {
  [data-reach-menu-button] ~ [data-reach-menu-popover] {
    position: absolute;
    width: auto;
  }
}

reach-portal {
  z-index: 9999;
}